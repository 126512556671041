import React from 'react';
import '../../../static/css/style.css'; // Ensure you have your CSS file imported
// import Portfolio from '../Portfolio/Portfolio';
import { Carousel } from 'react-bootstrap';
// Importing images          
import { useNavigate } from 'react-router-dom'; // Import useNavigate                                                    
// import logo from '../../../static/img/logo.png';  
import bg6 from '../../../static/img/bg7.jpg';
import bg11 from '../../../static/img/bg11.jpg';
import bg14 from '../../../static/img/bg14.jpg';
import bg12 from '../../../static/img/bg12.jpg';
import bg4 from '../../../static/img/bg4.jpg';
import bg13 from '../../../static/img/bg13.jpg';
import bg8 from '../../../static/img/bg8.jpg';
import bg9 from '../../../static/img/bg9.jpg';
import bg16 from '../../../static/img/bg16.jpg';
import test4 from '../../../static/img/testimonal/test4.jpg';
import test5 from '../../../static/img/testimonal/test5.jpg';
import test2 from '../../../static/img/testimonal/ts2.jpg';

const Home = () => {
    const navigate = useNavigate(); // Initialize useNavigate hook
    const testimonials = [
        { 
            imgSrc: test4, 
            name: 'Ruth Haile', 
             
            feedback: 'The exquisite craftsmanship of Wase Gemstone’s jewelry left me speechless! The Ethiopian Opal I purchased sparkles with a mesmerizing brilliance that never fails to attract compliments. This is more than just jewelry; it’s a true work of art!'
          },
          { 
            imgSrc: test5, 
            name: 'Richard Andrew', 
            
            feedback: 'I am absolutely in love with my new Emerald ring from Wase Gemstone! The attention to detail is outstanding, and the quality is unmatched. Each piece tells a story, and I feel proud to wear something so unique and beautifully crafted!'
          },
          { 
            imgSrc: test2, 
            name: 'Angela Yu', 
           
            feedback: 'Wase Gemstone offers an incredible selection of jewelry that perfectly combines elegance and style. The Sapphire pendant I bought is not only stunning but also made me feel special every time I wear it. Highly recommended for anyone looking to elevate their jewelry collection!'
          }
          ];
  return (
    <div>
      {/* page loading */}
      {/* <div id="loading">
        <div className="load-circle"><span className="one"></span></div>
      </div> */}
      {/* End */}

      {/* Header */}
      
      {/* End Header */}

      <main>
        {/* Home Banner */}
        <section id="home" className="home-banner" style={{ backgroundImage: `url(${bg6})` }}>
          <div className="overlay"></div>
          <div className="container">
            <div className="row align-items-center full-screen">
              <div className="col-lg-12">
                <div className="hb-typo">
                  <h1 className="font-alt slide-in">Wase Gemstone</h1>
                  <h2 className="fade-in-delay">We Don’t Just Make Jewelry; We Create Beauty and Elegance</h2>
                </div>
              </div>
            </div>
          </div>
          {/* Scroll Button */}
          <div className="scroll-bottom go-to">
            <a href="#about" className="scroll-btn">WG</a>
          </div>
          {/* Social Icons */}
          <div className="nav social-icons justify-content-center justify-content-md-end">
            <a href="#" target="_blank"><i className="ti-facebook"></i></a>
            <a href="#" target="_blank"><i className="ti-instagram"></i></a>
          </div>
        </section>
        {/* End Home Banner */}

        {/* About Us */}
        <section id="about" className="section about-section after-left-section">
  <div className="container">
    <div className="row align-items-center justify-content-center">
      <div className="col-lg-6 my-3">
        <div className="img-box">
          <img src={bg11} alt="About Wase Gemstone" />
        </div>
      </div>
      <div className="col-lg-6 my-3">
        <div className="typo-box">
          <h3>Wase Gemstone</h3>
          <p>
            At Wase Gemstone, we curate a stunning collection of handcrafted gemstone jewelry, including necklaces, rings, bracelets, earrings, and pendants. Each piece showcases the beauty of exquisite gemstones like Ethiopian Opal (Wollo Opal), Emerald from Shakiso, and Sapphire from Axum.
            Our selection includes semi-precious stones such as Amethyst, Garnet, and Aquamarine, as well as industrial stones like Lithium and Copper, available in polished and raw forms.
            We blend classic and contemporary designs to cater to diverse tastes, offering personalized creations in both silver and gold. At Wase Gemstone, we don’t just make jewelry; we create timeless treasures that tell your story.
          </p>

          {/* More About Us Button */}
          <button
                                    className="btn btn-primary"
                                    onClick={() => navigate('/About')} // Navigate to portfolio page
                                >
                                    More About Us
                                </button>
        </div>
      </div>
    </div>
  </div>
</section>

        {/* End About Me */}

        {/* Portfolio */}
        <section id="work" className="section gray-bg">
  <div className="container">
    <div className="row justify-content-center section-title text-center">
      <div className="col-lg-7">
        <h3 className="font-alt">Our Products</h3>
        <p>We design and make jewelry for our customers of all sizes, specializing in creating stylish, modern jewelry.</p>
      </div>
    </div>

    <div className="portfolio-content row lightbox-gallery">
      {[
        { title: 'Emerald', imgSrc: bg14 },
        { title: 'Rubby', imgSrc: bg12 },
        { title: 'Opal', imgSrc: bg6 },
        { title: 'Amethyst', imgSrc: bg4 },
        { title: 'Garnet', imgSrc: bg13 },
        { title: 'Sapphire', imgSrc: bg8 },
        { title: 'Topaz', imgSrc: bg9 },
        { title: 'Aquamarine', imgSrc: bg16 },
        { title: 'Pearl', imgSrc: bg11 },
      ].map((item, index) => (
        <div key={index} className="col-sm-6 col-lg-4 grid-item">
          <div className="portfolio-box-01">
            <div className="portfolio-info">
              <h5>{item.title}</h5>
            </div>
            <div className="portfolio-img">
              <img src={item.imgSrc} alt={item.title} />
            </div>
          </div>
        </div>
      ))}
    </div>

    {/* Add a "More Photos" Button */}
    <div className="row justify-content-center mt-5">
      <div className="col-lg-4 text-center">
      <button
                                    className="btn btn-primary"
                                    onClick={() => navigate('/gallery')} // Navigate to portfolio page
                                >
                                    More Photos
                                </button>
      </div>
    </div>
  </div>
</section>

        {/* End Portfolio */}
{/* video start */}
    {/* Fullscreen Video Section  from hagere tv */}
    <section id="video" className="section video-section">
        <div className="container-fluid p-0">
          <iframe
            width="100%"
            height="550px"
            src="https://www.youtube.com/embed/N1VRiScCvwM?si=bjaoBnUNUMFm96QN"
            title="Wase Gemstone on TV"
            frameBorder="1"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </div>
      </section>
{/* video end */}
        {/* Testimonials */}
        <section className="testimonials">
      <div className="heading text-center">
        <h2>What's Our <span>Clients</span> Says?</h2>
      </div>
      <div className="container">
        <Carousel>
          {testimonials.map((testimonial, index) => (
            <Carousel.Item key={index} className="text-center">
              <img decoding="async" src={testimonial.imgSrc} alt={testimonial.name} className="center-block team" />
              <h2>{testimonial.name}</h2>
              <h4>{testimonial.role}</h4>
              <h4>{testimonial.feedback}</h4>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </section>
        {/* End Testimonials */}

        {/* Contact Us */}
        <section id="contactus" className="section after-left-section">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 my-3">
            <div className="map-embed">
            <iframe
  title="Google Maps Location"
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3940.3609890123644!2d38.835555570283816!3d9.030796469163386!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b9153f090fb8b%3A0x6ab807066e71daf6!2sAselefeche%20Merga%20Hotel%20and%20Spa!5e0!3m2!1sen!2set!4v1729346086521!5m2!1sen!2set"
  width="100%"
  height="450"
  style={{ border: 0 }}
  allowFullScreen=""
  loading="lazy"
></iframe>
             </div>
          </div>

          <div className="col-lg-4 ml-auto my-3">
            <div className="contact-info">
              <div className="sm-title">
                <h4 className="font-alt">Contact us</h4>
              </div>
              <div className="media">
                <div className="icon">
                  <i className="ti-map"></i>
                </div>
                <span className="media-body">Residence: Ethiopia</span>
              </div>
              <div className="media">
                <div className="icon">
                  <i className="ti-email"></i>
                </div>
                <span className="media-body">Email: Wasgem@gmail.com</span>
              </div>
              <div className="media">
                <div className="icon">
                  <i className="ti-mobile"></i>
                </div>
                <span className="media-body">Phone: +251-980-431-252</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
        {/* End Contact Us */}
      
        {/* Footer */}
     
        {/* End Footer */}
      </main>
    </div>
  );
};

export default Home;
