import React, { useEffect, useState } from 'react';
import logo from '../../../static/img/logo.png';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

function Header() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);

  // Scroll event handler
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Toggle collapse state
  const toggleNavbar = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <header className={`main-header ${isScrolled ? 'scrolled' : ''}`}>
      <nav className={`navbar header-nav ${isScrolled ? 'header-colored' : 'header-transparent'} navbar-expand-lg`}>
        <div className="container">
          {/* Brand */}
          <Link className="navbar-brand" to="/">
            <img src={logo} alt="Logo" className="logo" />
            <span></span>
          </Link>
          {/* Mobile Toggle */}
          <button
            className={`navbar-toggler ${isScrolled ? 'scrolled-toggle' : ''}`}
            type="button"
            aria-expanded={!isCollapsed}
            aria-label="Toggle navigation"
            onClick={toggleNavbar}
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
          {/* Top Menu */}
          <div className={`collapse navbar-collapse justify-content-end ${!isCollapsed ? 'show' : ''}`} id="navbar-collapse-toggle">
            <ul className="navbar-nav ml-auto">
              <li><Link className={`nav-link ${isScrolled ? 'scrolled-text' : ''}`} to="/"><span>Home</span></Link></li>
              <li><Link className={`nav-link ${isScrolled ? 'scrolled-text' : ''}`} to="/about"><span>About Us</span></Link></li>
              <li><Link className={`nav-link ${isScrolled ? 'scrolled-text' : ''}`} to="/gallery"><span>Gallery</span></Link></li>
              <li><Link className={`nav-link ${isScrolled ? 'scrolled-text' : ''}`} to="/contact"><span>Contact</span></Link></li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
