import React from 'react';
import bg11 from '../../../static/img/ceo2.jpg';
import heroImg from '../../../static/img/bg8.jpg';
import certificateImg from '../../../static/img/certficate.jpg';
import exhibitionImg from '../../../static/img/ceo2.jpg';
import ceoImg from '../../../static/img/ceo1.jpg';
import { useNavigate } from 'react-router-dom';

function About() {
  const navigate = useNavigate();

  return (
    <>
      {/* Hero Section */}
      <section id="hero" className="hero-section" style={{ backgroundImage: `url(${heroImg})` }}>
        <div className="container text-center hero-content">
          <h1 className="display-4 text-white">Discover the Beauty of Wase Gemstone</h1>
          <p className="lead text-white">
            Handcrafted gemstone jewelry with exquisite Ethiopian Opal, Emerald, and Sapphire.
          </p>
          <button
            className="btn btn-light btn-lg mt-3"
            onClick={() => navigate('/gallery')}
          >
            Explore Our Collection
          </button>
        </div>
      </section>

      {/* About Us Section */}
      <section id="about" className="section about-section after-left-section">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 my-3">
              <div className="img-box">
                <img src={bg11} alt="About Wase Gemstone" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-6 my-3">
              <div className="typo-box">
                <h3>About Wase Gemstone</h3>
                <p>
                  At Wase Gemstone, we curate a stunning collection of handcrafted gemstone jewelry, including necklaces, rings, bracelets, earrings, and pendants. Each piece showcases the beauty of exquisite gemstones such as:
                </p>
                <ul>
                  <li><strong>Ethiopian Opal (Wollo Opal):</strong> Known for its vibrant play of color, sourced from Wollo Delanta, Ethiopia.</li>
                  <li><strong>Emerald from Shakiso:</strong> Featuring deep green hues, our emeralds are sourced from Shakiso, Ethiopia.</li>
                  <li><strong>Sapphire from Axum:</strong> Rich blue sapphires, crafted into timeless pieces, sourced from Axum.</li>
                  <li><strong>Amethyst, Garnet, and Aquamarine:</strong> Semi-precious stones with unique characteristics, perfect for custom designs.</li>
                  <li><strong>Industrial Stones:</strong> We also provide polished and raw forms of Lithium, Copper, and other industrial stones.</li>
                </ul>
                <p>
                  Blending classic and contemporary designs, we offer personalized creations in both silver and gold to cater to diverse tastes. 
                  At Wase Gemstone, we don’t just make jewelry; we create timeless treasures that tell your story.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Fullscreen Video Section */}
      <section id="video" className="section video-section">
        <div className="container-fluid p-0">
          <iframe
            width="100%"
            height="550px"
            src="https://www.youtube.com/embed/N1VRiScCvwM?si=bjaoBnUNUMFm96QN"
            title="Wase Gemstone on TV"
            frameBorder="1"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </div>
      </section>

      {/* Certifications Section */}
      <section id="certifications" className="section certification-section">
        <div className="container">
          <h2 className="text-center mb-5">Our Certifications</h2>
          <div className="row align-items-center">
            <div className="col-md-6">
              <img src={certificateImg} alt="Our Certifications" className="img-fluid" />
            </div>
            <div className="col-md-6">
              <p>
                Wase Gemstone is proud to be a certified distributor and designer of high-quality gemstones. We have been recognized by the Ethiopian Gemological Institute and are certified to meet international standards in gemstone sourcing, craftsmanship, and environmental sustainability.
              </p>
              <p>
                Our commitment to excellence ensures that all of our gemstones are ethically sourced and meet rigorous quality standards.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Exhibitions Section */}
      <section id="exhibitions" className="section exhibition-section">
        <div className="container">
          <h2 className="text-center mb-5">Exhibitions & Awards</h2>
          <div className="row align-items-center">
            <div className="col-md-6 order-md-2">
              <img src={exhibitionImg} alt="Exhibitions" className="img-fluid" />
            </div>
            <div className="col-md-6 order-md-1">
              <p>
                Wase Gemstone has participated in several international exhibitions, showcasing our unique pieces across the globe. Our award-winning designs have been featured in the World Jewelry Expo and the African Gemstone Conference, where we have earned accolades for craftsmanship and creativity.
              </p>
              <p>
                We are honored to be a part of the global gemstone and jewelry industry, representing Ethiopia's rich heritage in gemstone mining and jewelry making.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* CEO Section */}
      <section id="ceo" className="section ceo-section">
        <div className="container">
          <h2 className="text-center mb-5">Meet Our CEO</h2>
          <div className="row align-items-center">
            <div className="col-md-4">
              <img src={ceoImg} alt="CEO" className="img-fluid rounded-circle" />
            </div>
            <div className="col-md-8">
              <h3>Washihun Lakewu</h3>
              <p>
                As the founder and CEO of Wase Gemstone, Washihun Lakewu has been a driving force in the Ethiopian gemstone industry. With a passion for gemstones and an eye for design, Washihun has transformed Wase Gemstone into a leading brand known for its innovation, quality, and commitment to sustainability.
              </p>
              <p>
                Washihun's vision is to bring the beauty of Ethiopian gemstones to the world while maintaining ethical sourcing practices and supporting local communities involved in gemstone mining and craftsmanship.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
