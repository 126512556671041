import React, { useState } from 'react';
import bg6 from '../../../static/img/bg7.jpg';
import bg11 from '../../../static/img/bg11.jpg';
import bg14 from '../../../static/img/bg14.jpg';
import bg12 from '../../../static/img/bg12.jpg';
import bg4 from '../../../static/img/bg4.jpg';
import bg13 from '../../../static/img/bg13.jpg';
import bg8 from '../../../static/img/bg8.jpg';
import bg9 from '../../../static/img/bg9.jpg';
import bg16 from '../../../static/img/bg16.jpg';

function PortfolioPage() {
  const [filter, setFilter] = useState('All'); // State to manage selected filter

  const gemstones = [
    { title: 'Opal', imgSrc: bg6, category: 'Opal' },
    { title: 'Emerald', imgSrc: bg14, category: 'Emerald' },
    { title: 'Ruby', imgSrc: bg9, category: 'Ruby' },
    { title: 'Amber', imgSrc: bg6, category: 'Other' },
    { title: 'Onyx', imgSrc: bg14, category: 'Other' },
    { title: 'Citrine', imgSrc: bg13, category: 'Other' },
    { title: 'Peridot', imgSrc: bg12, category: 'Other' },
    { title: 'Turquoise', imgSrc: bg8, category: 'Other' },
    { title: 'Zircon', imgSrc: bg4, category: 'Other' },
    { title: 'Moonstone', imgSrc: bg11, category: 'Other' },
  ];

  // Filter gemstones based on the selected category
  const filteredGemstones = gemstones.filter((gemstone) => {
    if (filter === 'All') return true;
    return gemstone.category === filter;
  });

  return (
    <section id="portfolio-more" className="section gray-bg">
      <div className="container">
        <div className="row justify-content-center section-title text-center">
          <div className="col-lg-7">
            <h3 className="font-alt">More Gemstones</h3>
            <p>Discover additional gemstones in our collection, crafted with care and precision.</p>
          </div>
        </div>

        {/* Horizontal Filter Buttons */}
        <div className="row justify-content-center mb-4">
          <div className="btn-group" role="group" aria-label="Gemstone filter">
            <button className={`btn ${filter === 'All' ? 'btn-primary' : 'btn-outline-primary'}`} onClick={() => setFilter('All')}>All</button>
            <button className={`btn ${filter === 'Opal' ? 'btn-primary' : 'btn-outline-primary'}`} onClick={() => setFilter('Opal')}>Opal</button>
            <button className={`btn ${filter === 'Emerald' ? 'btn-primary' : 'btn-outline-primary'}`} onClick={() => setFilter('Emerald')}>Emerald</button>
            <button className={`btn ${filter === 'Ruby' ? 'btn-primary' : 'btn-outline-primary'}`} onClick={() => setFilter('Ruby')}>Ruby</button>
            <button className={`btn ${filter === 'Other' ? 'btn-primary' : 'btn-outline-primary'}`} onClick={() => setFilter('Other')}>Other</button>
          </div>
        </div>

        <div className="portfolio-content row lightbox-gallery">
          {filteredGemstones.map((item, index) => (
            <div key={index} className="col-sm-6 col-lg-4 grid-item">
              <div className="portfolio-box-01">
                <div className="portfolio-info">
                  <h5>{item.title}</h5>
                </div>
                <div className="portfolio-img">
                  <img src={item.imgSrc} alt={item.title} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default PortfolioPage;
