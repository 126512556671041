import React from 'react'
import Homee from '../Components/Home/Home';
function Home() {
  return (
    <>
        <Homee />
    </>
  )
}

export default Home;
