import React from 'react'

function Contact() {
  return (
    <section id="contactus" className="section after-left-section">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 my-3">
          <div className="map-embed">
          <iframe
title="Google Maps Location"
src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3940.3609890123644!2d38.835555570283816!3d9.030796469163386!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b9153f090fb8b%3A0x6ab807066e71daf6!2sAselefeche%20Merga%20Hotel%20and%20Spa!5e0!3m2!1sen!2set!4v1729346086521!5m2!1sen!2set"
width="100%"
height="450"
style={{ border: 0 }}
allowFullScreen=""
loading="lazy"
></iframe>
           </div>
        </div>

        <div className="col-lg-4 ml-auto my-3">
          <div className="contact-info">
            <div className="sm-title">
              <h4 className="font-alt">Contact us</h4>
            </div>
            <div className="media">
              <div className="icon">
                <i className="ti-map"></i>
              </div>
              <span className="media-body">Residence: Ethiopia</span>
            </div>
            <div className="media">
              <div className="icon">
                <i className="ti-email"></i>
              </div>
              <span className="media-body">Email: Wasgem@gmail.com</span>
            </div>
            <div className="media">
              <div className="icon">
                <i className="ti-mobile"></i>
              </div>
              <span className="media-body">Phone: +251-980-431-252</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Contact
