import React from 'react';
import { FaInstagram, FaLinkedinIn,  FaWhatsapp } from 'react-icons/fa';

function Footer() {
  return (
    <footer className="footer">
      <div className="container py-4">
        <div className="row align-items-center">
          
          {/* Social Media Section */}
          <div className="col-md-6 mb-3 mb-md-0 text-center text-md-left">
            <h5 className="mb-3">Connect with Us:</h5>
            <div className="social-icons">
              
             
              <a href="https://instagram.com" aria-label="Instagram" className="social-icon">
                <FaInstagram />
              </a>
              <a href="https://linkedin.com" aria-label="LinkedIn" className="social-icon">
                <FaLinkedinIn />
              </a>
             
              <a href="https://wa.me/yourwhatsappnumber" aria-label="WhatsApp" className="social-icon" target="_blank" rel="noopener noreferrer">
                <FaWhatsapp />
              </a>
            </div>
          </div>

          {/* Credits Section */}
          <div className="col-md-6 text-center text-md-right">
            <p>© 2024 Wase Gemstone. All rights reserved.</p>
            <p>
              Designed and Developed by 
              <a href="https://nati16.com" target="_blank" rel="noreferrer" className="designer-link"> Naru</a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
