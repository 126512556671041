import React from 'react';
import { Routes, Route } from "react-router-dom"; 
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './static/plugin/bootstrap/css/bootstrap.min.css'
import './static/plugin/themify-icons/themify-icons.css'
import './static/plugin/owl-carousel/css/owl.carousel.min.css'
import './static/plugin/magnific/magnific-popup.css'
import './static/css/style.css'
import Header from "./markup/Components/Header/Header";
import Home from './markup/pages/Home';
import Portfolio from './markup/Components/Portfolio/Portfolio';
import Footer from './markup/Components/Footer/Footer';
import About from './markup/Components/About/About';
import Contact from './markup/Components/Contact/Contact';
import NotFound from './markup/pages/404';
function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="gallery" element={<Portfolio />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  );
}
export default App;



